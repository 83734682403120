import product1 from './1.png';
import product2 from './2.png';
import product3 from './3.png';
import product4 from './4.png';
import product5 from './1.png';
import product6 from './2.png';
import product7 from './3.png';
import product8 from './4.png';


export const ordersData = [
  {
    CourseID: 10248,
    CustomerName: 'Vinet',
    InstructorName:'Vinet',
    DOR: '02/10/2023',
    TotalAmount: 32.38,
    CourseName: 'Cyber Security',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage: product6,
    CourseDt: "Cyber Security Ai",
    TrainingID : '15834',
    StartDate : '02/05/2023',
    EndDate : '08/05/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 345653,
    CustomerName: 'Carson Darrin',
    InstructorName: 'Carson Darrin',
    DOR: '02/11/2023',
    TotalAmount: 56.34,
    CourseName: 'AI Cybersecurity',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product5,
    CourseDt: "Cyber Special AWS ",
    TrainingID : '15837',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 390457,
    CustomerName: 'Fran Perez',
    InstructorName: 'Fran Perez',
    DOR: '02/11/2023',
    TotalAmount: 93.31,
    CourseName: 'Blockchain Ai',
    Location: 'New York',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product7,
    CourseDt: "Cyber Security Ai",
    TrainingID : '15222',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 893486,
    CustomerName: 'Anika Viseer',
    InstructorName: 'Anika Viseer',

    DOR: '02/09/2023',
    TotalAmount: 93.31,
    CourseName: 'AWS',
    Location: 'Germany',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage: product4,
    CourseDt: "Cyber Security Ai",
    TrainingID : '1517',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 748975,
    CustomerName: 'Miron Vitold',
    InstructorName: 'Miron Vitold',
    DOR: '08/08/2023',
    TotalAmount: 23.99,
    CourseName: 'AWS Champion',
    Location: 'Spain',
    Status: 'canceled',
    StatusBg: 'red',
    ProductImage: product1,
    CourseDt: "Cyber Special AWS ",
    TrainingID : '15127',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 94757,
    CustomerName: 'Omar Darobe',
    InstructorName: 'Omar Darobe',
    DOR: '08/08/2023',
    TotalAmount: 95.99,
    CourseName: 'Blockchain Crpto',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage: product2,
    CourseDt: "Cyber Security Ai",
    TrainingID : '155697',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 944895,
    CustomerName: 'Lulia albu',
    InstructorName: 'Lulia albu',
    DOR: '08/08/2023',
    TotalAmount: 17.99,
    CourseName: 'Oracle',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product3,
    CourseDt: "Cyber Security Ai",
    TrainingID : '15887',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 845954,
    CustomerName: 'Penjani',
    InstructorName: 'Penjani',
    DOR: '08/08/2023',
    TotalAmount: 59.99,
    CourseName: 'Oracle Database',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product4,
    CourseDt: "Cyber Special AWS ",
    TrainingID : '23897',
    StartDate : '08/08/2023',
    EndDate : '08/07/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  
  },
  {
    CourseID: 845954,
    CustomerName: 'Jie Yan',
    InstructorName: 'Jie Yan',
    DOR: '08/08/2023',
    TotalAmount: 87.99,
    CourseName: 'AWS Diana',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage: product5,
    CourseDt: "Cyber Security Ai",
    TrainingID : '35897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 874534,
    CustomerName: 'Alex',
    InstructorName: 'Alex',
    DOR: '08/08/2023',
    TotalAmount: 122.99,
    CourseName: 'Diana Junior',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage: product6,
    CourseDt: "Cyber Special AWS ",
    TrainingID : '67897',
    StartDate : '08/08/2023',
    EndDate : '08/07/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 38489,
    CustomerName: 'Miron',
    InstructorName: 'Miron',
    TotalAmount: 87.99,
    CourseName: 'Diana AI',
    DOR: '07/08/2023',
    Location: 'USA',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product7,
    CourseDt: "Cyber Security Ai",
    TrainingID : '74897',
    StartDate : '08/08/2023',
    EndDate : '08/07/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 24546,
    CustomerName: 'Frank',
    InstructorName: 'Frank',
    TotalAmount: 84.99,
    CourseName: 'Diana CyberSecurity',
    DOR: '08/11/2023',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product8,
    CourseDt: "Cyber Special AWS ",
    TrainingID : '96897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 874534,
    CustomerName: 'Danai',
    InstructorName: 'Danai',
    TotalAmount: 122.99,
    CourseName: 'Watch',
    DOR: '08/08/2024',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage: product1,
    CourseDt: "Cyber Security Ai",
    TrainingID : '15897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 10248,
    CustomerName: 'Vinet',
    InstructorName: 'Vinet',
    TotalAmount: 32.38,
    CourseName: 'Fresh Tomato',
    Location: 'USA',
    Status: 'pending',
    StatusBg: '#FB9678',
    ProductImage: product6,
    CourseDt: "Cyber Special AWS ",
    TrainingID : '15897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 345653,
    CustomerName: 'Carson Darrin',
    InstructorName: 'Carson Darrin',
    TotalAmount: 56.34,
    CourseName: 'Butter Scotch',
    Location: 'Delhi',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product5,
    TrainingID : '15897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 390457,
    CustomerName: 'Fran Perez',
    InstructorName: 'Fran Perez',
    TotalAmount: 93.31,
    CourseName: 'Candy Gucci',
    Location: 'New York',
    Status: 'complete',
    StatusBg: '#8BE78B',
    ProductImage: product7,
    TrainingID : '15897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 893486,
    CustomerName: 'Anika Viseer',
    InstructorName: 'Anika Viseer',
    TotalAmount: 93.31,
    CourseName: 'Night Lamp',
    Location: 'Germany',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage: product4,
    TrainingID : '15897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 748975,
    CustomerName: 'Miron Vitold',
    InstructorName: 'Miron Vitold',
    TotalAmount: 23.99,
    CourseName: 'Healthcare Erbology',
    Location: 'Spain',
    Status: 'canceled',
    StatusBg: 'red',
    ProductImage: product1,
    TrainingID : '15897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
  {
    CourseID: 94757,
    CustomerName: 'Omar Darobe',
    InstructorName: 'Omar Darobe',
    TotalAmount: 95.99,
    CourseName: 'Makeup Lancome Rouge',
    Location: 'USA',
    Status: 'canceled',
    StatusBg: '#FF5C8E',
    ProductImage: product2,
    TrainingID : '15897',
    StartDate : '02/08/2023',
    EndDate : '08/03/2024',
    PassWord : 'Diana@123',
    StatusBro : 'ongoing',
    StatusBgBro: '#8BE78B',
    UpdatedDate: '12/08/2023',
  },
];
